import(/* webpackMode: "eager" */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/node_modules/next/font/google/target.css?{\"path\":\"src/app/(main)/layout.tsx\",\"import\":\"Urbanist\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-primary\"}],\"variableName\":\"urbanist\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/providers/app-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/providers/tanstack.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/app/providers/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2243533541/src/chikitsa-ai-website/src/styles/globals.css");
