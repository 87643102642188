'use client';

import React, { createContext, useContext, useMemo, useState } from 'react';

interface AppContextType {
  userId: string | null;
  setUserId: React.Dispatch<React.SetStateAction<string | null>>;
}

// Create the context with the appropriate type
export const AppContext = createContext<AppContextType>({
  userId: null,
  setUserId: () => null,
});

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [userId, setUserId] = useState<string | null>(null);
  const value = useMemo(() => ({ userId, setUserId }), [userId, setUserId]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }

  return context;
};
